<template>
  <div>
    <iframe v-if="isshow" :src="trustSrc" class="iframe-style" frameborder="0"></iframe>

    <div v-if="!isshow" class="iframe-style">
      <header class="header">新疆维吾尔自治区政府非税缴费</header>
      <div class="imageBox">
        <div id="topImg">
          <img :src="logoSrc" alt="非税缴费logo" />
        </div>
      </div>
      <div id="inputForm">
        <div class="formText">业务号：</div>
        <el-input placeholder="请输入业务号" v-model="orderNumber" class="inputClass" clearable> </el-input>
      </div>
      <div id="quertButton">
        <el-button round @click="queryFun" :loading="submitLoading">缴 费</el-button>
      </div>
    </div>
    <!--  后台配置权限该功能是否在建  -->
    <!-- <no-data v-else :data="dataNo"></no-data> -->
  </div>
</template>

<script>
import payOnline from '@iebdc/api/pay-online';
import hasConstruction from '@iebdc/mixin/hasConstruction';
export default {
  mixins: [hasConstruction],
  computed: {},
  data() {
    return {
      isshow: false,
      trustSrc: '',
      submitLoading: false,
      dataNo: {
        src: require('@/pages/iebdc/assets/images/nodata@2x.png'),
        imgWidth: '242px',
        imgHeight: '194px',
        title: '敬请期待',
        description: '正在建设中...',
      },
      logoSrc: require('src/pages/iebdc/assets/images/jiaofeilogo.png'),
      orderNumber: '',
    };
  },
  mounted() {
    this.returnTrustSrc();
  },
  methods: {
    queryFun() {
      if (this.orderNumber === '') {
        this.$message({
          type: 'warning',
          message: '请录入业务号',
        });
      } else {
        this.submitLoading = true;
        payOnline.selectPayApplyPageList({ orderNO: this.orderNumber }).then((res) => {
          if (res.success) {
            if (res.data.page.length > 0) {
              this.goOnlinePay(res.data.page[0].id);
            } else {
              this.$message.error('未查询到缴费信息！');
              this.submitLoading = false;
            }
          }
        });
      }
    },
    goOnlinePay(orderid) {
      payOnline.payApply({ id: orderid }).then((res) => {
        this.submitLoading = false;
        if (res.success) {
          if (JSON.parse(res.data).orderId) {
            // this.isshow = true;
            window.open(
              this.$decryptByDES(window.CONFIG.czjf) + '?orderId=' + JSON.parse(res.data).orderId + '&deviceType=1'
            );
          } else {
            if (res.data.indexof('开票失败') > -1) {
              this.$message.success('开票结果审核中');
            } else this.$message.error('财政服务调用：' + JSON.parse(res.data).respmsg);
          }
        }
      });
    },
    returnTrustSrc() {
      if (this.$route.query.orderNo) {
        window.open(this.$decryptByDES(window.CONFIG.czjf) + '?orderId=' + this.$route.query.orderNo + '&deviceType=1');
        this.trustSrc =
          this.$decryptByDES(window.CONFIG.czjf) + '?orderId=' + this.$route.query.orderNo + '&deviceType=1';
        //   trustSrc = trustSrc + '?orderId=b1143b84-e30d-48c8-b9a0-0d219abc6d6d"' + this.$route.params.orderNo + 'deviceType=1';
        // trustSrc = 'www.baidu.com';
        // this.isshow = true;
        this.$forceUpdate();
      }
    },
  },
};
</script>

<style scoped>
.iframe-style {
  margin-left: calc(50% - 600px);
  width: 1200px;
  height: 550px;
  background-color: #eef2f5;
}
.header {
  top: 0rem;
  left: 20%;
  right: 20%;
  height: 3.25rem;
  line-height: 3.25rem;
  color: #8c6b6b;
  text-align: center;
  font-size: 1.0625rem;
  font-weight: 500;
  font-family: NumberFont;
}
.imageBox {
  background-color: #fff;
  padding: 0 0 2rem 0;
  text-align: center;
}
#topImg {
  height: 10rem;
  -webkit-background-size: 6rem;
  -moz-background-size: 6rem;
  background-size: 6rem;
  padding-top: 4rem;
}

#inputForm {
  display: flex;
  background-color: #fff;
  margin: 1rem 0;
}
.formText {
  width: 50%;
  height: 52px;
  line-height: 52px;
  margin: 0 0 0 1rem;
  color: #9d9d9d;
}
/deep/ .el-input--small .el-input__inner {
  height: 52px;
  padding: 6px 0;
  border: 0;
  font-size: 15px;
  font-weight: 500;
}
.el-button--small {
  width: 94%;
  height: 2.375rem;
  border-radius: 1.5rem;
  margin: 3% 3% auto 3%;
  border: none;
  background: #ee5a18;
  color: #fff;
  font-size: 0.875rem;
}
</style>
